import React, { useState, useEffect } from 'react';
import { Select } from '@radix-ui/themes';

const models = [
  { id: 'gpt4o', name: 'GPT-4o' },
  { id: 'o3-mini', name: 'o3-mini' }
];

export const ModelSelectorComponent = ({ onModelChange, defaultModel }) => {
  const [selectedModel, setSelectedModel] = useState(defaultModel || 'gpt4o');

  useEffect(() => {
    if (defaultModel) {
      setSelectedModel(defaultModel);
    }
  }, [defaultModel]);

  const handleChange = (value) => {
    setSelectedModel(value);
    onModelChange(value);
  };

  return (
    <Select.Root value={selectedModel} onValueChange={handleChange}>
      <Select.Trigger />
      <Select.Content>
        {models.map((model) => (
          <Select.Item key={model.id} value={model.id}>
            {model.name}
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};