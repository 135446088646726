import React, {useEffect, useRef, useState} from 'react';
import {Blockquote, Box, Code, Container, Flex, Heading, Section, Separator, Text} from '@radix-ui/themes';
import LoginComponent from './components/login';
import {FileUploaderComponent} from './components/fileUploader';
import {ProgressComponent} from './components/progressBar';
import {DownloadLinksComponent} from './components/downloadLinks';
import {LanguageSelectorComponent} from './components/languageSelector';
import {getCurrentUser} from 'aws-amplify/auth';
import {RotatingLines} from 'react-loader-spinner';
import {getUserInfo, resetAccessToken} from './lib/server-api';
import {extractAudio} from './lib/audioExtractor';
import {whisperTranscript} from './lib/open-ai';
import {prepareForTranslate, translate} from './lib/srtProcessor';
import { ModelSelectorComponent } from "./components/modelSelector";
// Import other needed Radix UI components here

const App = () => {
    const isFirstRun = useRef(true);
    const [fileProcessingProgress, setFileProcessingProgress] = useState(0);
    const [translationLanguage, setTranslationLanguage] = useState('Chinese');
    const [inputLanguage, setInputLanguage] = useState('English');
    const [selectedModel, setSelectedModel] = useState('gpt-4o');
    const [authUser, setAuthUser] = useState({}); // can not default to null, otherwise set authUser will not trigger useEffect
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);
    const [info, setInfo] = useState(null);

    const [originalSrt, setOriginalSrt] = useState('');
    const [translatedSrt, setTranslatedSrt] = useState('');

    const resultStore = useRef([]);
    const timestampStore = useRef([]);

    const handleTargetLanguageChange = (language) => {
        setTranslationLanguage(language.name);
        // You will also need to handle the translation process here, using the `language.code`
    };
    const handleInputLanguageChange = (language) => {
        setInputLanguage(language.name);
        // You will also need to handle the translation process here, using the `language.code`
    };

    const handleModelChange = (model) => {
        // Map model IDs to actual model identifiers required by the API
        const modelMapping = {
            'gpt4o': 'gpt-4o',
            'o3-mini': 'o3-mini'
        };

        setSelectedModel(modelMapping[model] || 'gpt-4o');
        console.log(`Selected model changed to: ${modelMapping[model]}`);
    };

    // make it better layout size on mobile
    useEffect(() => {
        const containerId = 'xiHAgULB';
        // <!-- Matomo Tag Manager -->
        const _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        (function () {
            const d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
            g.async = true;
            g.src = `https://a.logcg.com/js/container_${containerId}.js`;
            s.parentNode.insertBefore(g, s);
        })();
        // <!-- End Matomo Tag Manager -->
    }, []);
    useEffect(() => {
        if (fileProcessingProgress >= 100) {

        }
    }, [fileProcessingProgress]);

    useEffect(() => {
        getCurrentUser()
            .then((user) => setAuthUser(user))
            .catch((e) => {
                console.log(e);
                setAuthUser(null);
            }).finally(() => {
            isFirstRun.current = false;
        });

    }, []);

    useEffect(() => {
        if (authUser && Object.entries(authUser).length !== 0) {
            getUserInfo().then((data) => {
                if (data.accessToken) {
                    setUserData(data);
                    return true;
                } else {
                    return false;
                }
            }).then((success) => {
                // if user account have no access token, reset it
                if (success) return;
                resetAccessToken().then((data) => setUserData(data));
            });
        }
    }, [authUser]);


    // Replace this with real file processing logic
    const handleFileUpload = async (file) => {
        const accessToken = userData['accessToken'];
        timestampStore.current = [];
        resultStore.current = [];
        setOriginalSrt('');
        setTranslatedSrt('');
        setError(null);
        setFileProcessingProgress(0);
        try {
            setInfo('Extract audio from video file...');
            setFileProcessingProgress(5);
            const audioBlob = await extractAudio(file);

            setFileProcessingProgress(20);
            setInfo('Transcribing audio file...');
            const originalSrt = await whisperTranscript(accessToken, audioBlob); // get the audio blob

            setFileProcessingProgress(40);
            setInfo('Prepare for translate...');
            const srtParts = prepareForTranslate(originalSrt);

            for (let i = 0; i < srtParts.length; i++) {
                resultStore.current[i] = '';
            }
            setFileProcessingProgress(50);
            setInfo(`${selectedModel === 'gpt-4o' ? 'GPT-4o' : 'O3-mini'} is translating...`);
            await translate(srtParts, inputLanguage, translationLanguage, resultStore, selectedModel);

            setFileProcessingProgress(80);
            setInfo('Building SRT file...');
            const translatedSrt = resultStore.current.join('\n');

            setTranslatedSrt(translatedSrt);
            setOriginalSrt(originalSrt);
            setFileProcessingProgress(100);
            setInfo('Estimating cost...');
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            const oldCredit = userData.credit;
            getUserInfo().then((data) => {
                setUserData(data);
                setInfo('Estimated points used: ' + (oldCredit - data.credit).toFixed(6) + ' points');
            });
        }
    };

    return (
        <Box>
            <Section size="2">
                <Heading align="center">
                    Srt-AI
                </Heading>
                <Container size="5" p="5">
                    <Flex direction="row" justify="center" gap="3">
                        <Flex direction="column" align="center" gap="2">
                            <Text>
                                Please set language then drop your video file below or click to select one to start.
                            </Text>
                            <Text>
                                Your video file will be locally processed, never uploaded.
                            </Text>
                            <Separator></Separator>
                            {isFirstRun.current && <RotatingLines
                                strokeColor="#fff"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="20"
                                wrapperClassName="mx-auto"
                                visible={true}
                            />}
                            {!authUser && <LoginComponent authUser={authUser}/>}
                            {userData
                                ? <Text>{`Welcome ${authUser.username}, point balance: ${userData.credit}`}</Text>
                                : <RotatingLines
                                    strokeColor="#fff"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="20"
                                    wrapperClassName="mx-auto"
                                    visible={true}
                                />
                            }
                        </Flex>
                    </Flex>

                </Container>
            </Section>
            <Container>
                <Flex direction="row" justify="center" align="center" gap="3">
                    <Text>Translate from</Text>
                    <LanguageSelectorComponent onLanguageChange={handleInputLanguageChange} defaultCode="en"/>
                    <Text>to</Text>
                    <LanguageSelectorComponent onLanguageChange={handleTargetLanguageChange} defaultCode="zh"/>
                    <Text>using</Text>
                    <ModelSelectorComponent onModelChange={handleModelChange} defaultModel="gpt4o"/>
                </Flex>
                <Section size="2">
                    <Flex direction="column" justify="center" align="center" gap="3">
                        <FileUploaderComponent onFileUpload={handleFileUpload}/>
                        <Container
                            display={fileProcessingProgress >= 100 || fileProcessingProgress <= 0 ? 'none' : 'block'}>
                            <ProgressComponent progress={fileProcessingProgress}/>
                        </Container>
                        <Container>
                            {info && <Code variant="soft">
                                {info}
                            </Code>}
                            {error && <Code variant="soft">
                                {error && error.message}
                            </Code>}
                        </Container>
                        <Section size="1">
                            <DownloadLinksComponent
                                englishSrtTxt={originalSrt}
                                translatedSrtTxt={translatedSrt}
                                originalLanguage={inputLanguage}
                                translationLanguage={translationLanguage}
                            />
                        </Section>
                        <Section>
                            <Blockquote>
                                <Text weight="bold">Limitations:</Text>
                                <li>This tool is at alpha stage, the translated content could be buggy and inaccurate,
                                    please keep that in mind.
                                </li>
                                <li>Your video content remains on your device, we only upload processed mono audio file
                                    to the model.
                                </li>
                                <li>Whisper and GPT models (GPT-4o or o3-mini) have been used to generate the translated SRT file.</li>
                                <li>The audio split function is working in progress, current max audio file size limit
                                    to 20MB.
                                </li>
                                <li>The worker mechanism hasn't been implemented, the browser might froze during audio
                                    extraction.
                                </li>
                            </Blockquote>
                        </Section>
                    </Flex>
                </Section>
            </Container>
        </Box>
    );
};


export default App;
